import { StaticImage } from "gatsby-plugin-image";
import React, { FunctionComponent, ReactNode } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Pàgina } from "./Pàgina";

export const PàginaPrincipal: FunctionComponent<{ títol: string, children: ReactNode }> = ({ títol, children }) => (
  <Pàgina títol={títol}>
    <div style={{ height: '20vw', overflow: 'clip' }}>
      <StaticImage layout="fullWidth" imgStyle={{ height: '25vw' }} objectFit="cover" src="../imatges/Capçalera Lina Òdena.jpeg" alt="La Lina es queda al barri!"/>
    </div>
    <Container fluid="sm" className="p-4">
      <main style={{ fontFamily: 'Montserrat,Helvetica,Arial,Lucida,sans-serif', fontWeight: 300 }}>
        <Row className="row-cols-1 row-cols-lg-2 justify-content-center g-4">
          {children}
        </Row>
      </main>
    </Container>
  </Pàgina>
);
